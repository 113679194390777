@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: 'achaplorer-icons';
  src: url('../fonts/achaplorer-icons.ttf?8v3wmv') format('truetype'),
    url('../fonts/achaplorer-icons.woff?8v3wmv') format('woff'),
    url('../fonts/achaplorer-icons.svg?8v3wmv#achaplorer-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  font-family: 'DM Sans', sans-serif;
}

option {
  color: black;
}
* {
  box-sizing: border-box !important;
}
