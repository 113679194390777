.react-datepicker-wrapper {
  @apply flex w-full;
}
.react-datepicker {
  @apply select-none border-slate-200 shadow-lg rounded-xl;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  @apply border-b-white;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  @apply border-b-slate-200;
}
.react-datepicker__header {
  @apply border-none bg-transparent;
}
.react-datepicker__header .arrow-button {
  @apply p-1 rounded-full hover:bg-black/[.1] transition-colors;
}
.react-datepicker__header .arrow-button:disabled {
  @apply opacity-30 pointer-events-none;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__day-name:hover,
.react-datepicker__day:hover {
  @apply rounded-full w-8 h-8 mx-1.5 my-0.5 inline-flex items-center justify-center;
}
.react-datepicker__day-name {
  @apply opacity-50;
}

.react-datepicker__day-name,
.react-datepicker__day {
  @apply bg-white;
}
.react-datepicker__day.react-datepicker__day--disabled {
  @apply bg-white opacity-30 pointer-events-none text-inherit;
}
.react-datepicker__day.react-datepicker__day--outside-month {
  @apply opacity-0 pointer-events-none;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  @apply bg-primary text-primary-contrastText;
}
