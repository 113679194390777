.ck-content p {
  @apply mb-4;
}
.ck-content .heading-3 {
  @apply text-slate-700 text-3xl font-semibold mb-3;
}
.ck-content .heading-4 {
  @apply text-slate-700 text-2xl font-semibold mb-2.5;
}
.ck-content .heading-5 {
  @apply text-slate-700 text-xl font-semibold mb-2;
}
.ck-content .bullet-list {
  @apply list-disc;
}
.ck-content .bullet-list li {
  @apply ms-4;
}
.ck-content ol,
.ck-content ul {
  @apply ms-4;
}
.ck-content ol li,
.ck-content ul li {
  @apply ms-4 mb-2;
}
